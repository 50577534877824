import { MERCH_ARTICLES } from "../data/merchData";
import MerchArticle from "../components/Content/MerchArticle";
import'../styles/Merch.scss';

export default function Merch(){
    return (
        <div className="content">
            <div className="media-content">
                <div className="content-text">
                    <h3 className="content-headline">Contact us via e-mail: frostshockofficial@gmail.com if you are interested in our merch</h3>
                </div>
            </div>
            <div className="merch-article-container">
            {MERCH_ARTICLES.map(article => 
                <MerchArticle key={article.title}
                              title={article.title} 
                              price={article.price} 
                              images={article.images}
                              availability={article.availabilty}
                />)}
            </div>
        </div>
    );
}