import Bandmember from "../components/Content/BandMember";
import { BAND_MEMBER } from "../data/bandData";
import '../styles/Band.scss';

export default function Band(){
    return(
        <div className="content">
            <div className="band-content">
                <div className="band-all"></div>
                <div className="band-members">
                    {BAND_MEMBER.map(member => <Bandmember key={member.name} name={member.name} instrument={member.instrument} image={member.image}/>)}
                </div>
            </div>
        </div>
    );
}