import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import '../styles/Layout.scss';
import CartProvider from '../store/CartProvider';

export default function Layout() {
    return (
        // <CartProvider>
        //     <div className="background-logo"></div>
        //     <Header />
        //     <Outlet />
        //     <Footer />
        // </CartProvider>

        <>
            <div className="background-logo"></div>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}