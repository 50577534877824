import artbeatLogo from '../images/partnerLogos/Artbeat-logo-300x101.jpg';

export default function Partner(){
    return(
        <div className="content">
            <div className="partner-content">
                <a href="https://www.artbeat-stix.com/">
                    <img src={artbeatLogo} alt="partner-logo"/>
                </a>
            </div>
        </div>
    );
}