import { Link } from "react-router-dom";
import '../../styles/Header.scss';
import logo from '../../images/logo.jpeg'

export default function Header(){
    return(
        <>        
            <div className="header">
                <div className="header-nav">
                    <li><Link to='/media'>Media</Link></li>
                    <li><Link to='/band'>Band</Link></li>
                    <li>
                        <Link to='/'>                
                            <div className="crop">
                                <img src={logo} alt="main-logo"/>
                            </div>
                        </Link>
                    </li>
                    <li><Link to='/tour'>Tour</Link></li>
                    <li><Link to='/merch'>Merch</Link></li>
                </div>
                <hr/>
            </div>  
        </>
    )
}