export default function Imprint(){
    return(
        <div className="content">
            <div className="imprint-content">
                <h3>Frostshock</h3>
                <p>Mathias Michalczak</p>
                <p>frostshockofficial@gmail.com</p>
                <br/>
                <p><strong>Represented by</strong></p>
                <p>Fabian Schieffers</p>
                <p>Flemming Wehren</p>
                <p>Daniel Falk</p>
                <br/>
            </div>
        </div>
    );
}