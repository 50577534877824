import { Link } from "react-router-dom";
import '../../styles/Footer.scss';
import '../../styles/Album.scss';
import logo from '../../images/linktree-white.svg'

export default function Footer(){
    return(
        <div className="footer">
            <div className="footer-content">
                <a href="https://www.facebook.com/frostshockofficial" className="fa fa-facebook"></a>
                <a href="https://www.instagram.com/frostshockofficial/" className="fa fa-instagram"></a>
                <a href="https://www.youtube.com/@frostshockmetal" className="fa fa-youtube"></a>
                <a href="https://frostshock.bandcamp.com" className="fa fa-bandcamp"></a>
                <a href="mailto:frostshockofficial@gmail.com" className="fa fa-envelope"></a>

            </div>
            <div className="footer-info">
                <div>&copy; 2020 Frostshock</div>
                <Link to='/partner' className='footer-info-link'>Partner</Link>
                <Link to='/imprint' className='footer-info-link'>Imprint</Link>
                <a href="https://linktr.ee/frostshock" className="album-ref">
                    <img src={logo} alt="linktree"/>
                </a>
            </div>
        </div>
    );
}