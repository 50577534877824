import useScript from "../hooks/useScript";

export default function Tour(){
    useScript('//widget-app.songkick.com/injector/10273221');

    return (    
        <div className="content">
            <div className="main-container">
                <a href="https://www.songkick.com/artists/10273221" 
                    className="songkick-widget" 
                    data-theme="dark" 
                    data-track-button="on" 
                    data-detect-style="off" 
                    data-background-color="rgb(0,0,0,1)" 
                    data-font-color="rgb(255,255,255,1)" 
                    data-button-bg-color="rgb(255,255,255,1)" 
                    data-button-text-color="rgb(0,0,0,1)" 
                    data-locale="en" 
                    data-other-artists="on" 
                    data-share-button="on" 
                    data-country-filter="on" 
                    data-rsvp="on" 
                    data-request-show="on" 
                    data-past-events="on" 
                    data-past-events-offtour="off" 
                    data-remind-me="off">
                </a>
            </div>
        </div>
    )
}