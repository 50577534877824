import Home from './pages/Home';
import Media from './pages/Media';
import Layout from './pages/Layout';
import Band from './pages/Band';
import Tour from './pages/Tour';
import Merch from './pages/Merch';
import Imprint from './pages/Imprint';
import Partner from './pages/Partner';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  { 
    path: '/', 
    element: <Layout/>,
    children:[
      { path: '/', element: <Home/> },
      { path: '/media', element: <Media/> },
      { path: '/band', element: <Band/> },
      { path: '/tour', element: <Tour/> },
      { path: '/merch', element: <Merch/> },
      { path: '/imprint', element: <Imprint/> },
      { path: '/partner', element: <Partner/> },
    ]
  },
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;


