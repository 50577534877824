import { VIDEOS } from "../data/mediaData";
import MediaContainer from "../components/Content/MediaContainer";
import '../styles/Media.scss';

export default function Media(){
    return(
        <>
            <div className="content">
                {VIDEOS.map(video => <MediaContainer key={video.title} headLine={video.title} link ={video.link}/>)}
            </div>
        </>
    );
}