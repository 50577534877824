import { createContext, useReducer, useRef } from "react";
import '../styles/Cart.scss';
import shoppingCartLogo from '../images/shopping-cart.png'
import CartModal from "../components/Content/CartModal";

export const CartContext = createContext({
    items: [],
    addItem: () => { },
    updateQuantity: () => { }
});

function shoppingCartReducer(state, action) {
    if (action.type === 'ADD_ITEM'){
        const updatedItems = [...state.items];
        const existingIndex = updatedItems.findIndex(cartItem => cartItem.title === action.payload);
        const existingItem = updatedItems[existingIndex];
        if (existingItem) {
            const updatedItem = {
                ...existingItem,
                qty: existingItem.qty + 1,
            };
            updatedItems[existingIndex] = updatedItem;
        } else {
            updatedItems.push({
                title: action.payload,
                qty: 1
            });
        }
        return { items: updatedItems };
    }

    if (action.type === 'UPDATE_ITEM'){
        const updatedItems = [...state.items];
        const updatedItemIndex = updatedItems.findIndex(cartItem => cartItem.title === action.payload.title);
        const updatedItem = {
            ...updatedItems[updatedItemIndex]
        };

        updatedItem.qty += action.payload.amount;

        if (updatedItem.qty <= 0) {
            updatedItems.splice(updatedItemIndex, 1);
        } else {
            updatedItems[updatedItemIndex] = updatedItem;
        }

        return {
            items: updatedItems
        };
    }

    return state;
}

export default function CartProvider({ children }) {
    const [shoppingCartState, shoppingCartDispatch] = useReducer(shoppingCartReducer, {
        items: [],
    });

    function handleAddItemToCart(title) {
        shoppingCartDispatch({
            type: 'ADD_ITEM',
            payload: title
        });
    }

    function updateItemQuantity(title, amount) {
        shoppingCartDispatch({
            type: 'UPDATE_ITEM',
            payload: {
                title,
                amount
            }
        });
    }

    const ctxValue = {
        items: shoppingCartState.items,
        addItem: handleAddItemToCart,
        updateQuantity: updateItemQuantity
    }

    const dialog = useRef();
    function showCartModal(){
        dialog.current.showModal();
    }


    return <CartContext.Provider value={ctxValue}>
        {(shoppingCartState.items && shoppingCartState.items.length > 0) &&
        <>
            { dialog && <CartModal ref={dialog} cartItems={shoppingCartState.items}/>}
            <button className="cart" onClick={showCartModal}>
                <span className="cart-container">
                    <img class="cart-image" src={shoppingCartLogo} alt="cart" />
                    <div class="cart-counter">{shoppingCartState.items.length} </div>
                </span>
            </button>
        </>}

        {children}
    </CartContext.Provider>
}