export const VIDEOS = [
    {
        title: 'Whatever Lies Beyond The Black Sky',
        link: 'https://www.youtube.com/embed/A01gLvw81aw'
    },    
    {
        title: 'Emerged',
        link: 'https://www.youtube.com/embed/AGMg8ys8_zA'
    },
    {
        title: 'Angrist',
        link: 'https://www.youtube.com/embed/13OG2MOfkXw'
    },
    {
        title: 'Bludgeoned',
        link: 'https://www.youtube.com/embed/sqsDOd1aSfM'
    },
    {
        title: 'Morbus Influencer',
        link: 'https://www.youtube.com/embed/KxnHcvdVHg0'
    },
    {
        title: 'Putrefaction Of The Soul',
        link: 'https://www.youtube.com/embed/BHbGwCudzhw'
    },
];