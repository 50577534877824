import bildFlemsi from '../images/band/Flemtron.jpg';
import bildFabi from '../images/band/Skiber.jpg';
import bildMatze from '../images/band/Matzi.JPG';
import bildDaniel from '../images/band/Dani.jpg';

export const BAND_MEMBER = [
    {
        name: 'Flemming Wehren',
        instrument: 'Vocals/Guitar',
        image: bildFlemsi
    },
    {
        name: 'Fabian Schieffers',
        instrument: 'Drums',
        image: bildFabi
    },  
    {
        name: 'Daniel Falk',
        instrument: 'Bass',
        image: bildDaniel
    },  
    {
        name: 'Mathias Michalczak',
        instrument: 'Guitar',
        image: bildMatze
    },      
];