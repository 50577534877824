export default function MediaContainer({headLine, link}){
    return (
        <div className="media-content">
            <div className="content-text">
                <h3 className="content-headline">{headLine}</h3>
            </div>
            <iframe allow="fullscreen;" src={link}  loading="lazy">
            </iframe>
        </div>
    );
}