import CartItem from "./CartItem";
import { forwardRef } from "react";

const CartModal = forwardRef(function CartModal({ cartItems }, ref) {
    return (
        <dialog ref={ref} className="cart-modal">
            <h2>Hi</h2>
            <ol>
                {cartItems.map(item => <CartItem title={item.title} qty={item.qty} />)}
            </ol>
            <form method="dialog">
                <button>Close</button>
            </form>
        </dialog>
    );
});

export default CartModal;