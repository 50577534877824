export default function Bandmember({name, instrument, image}){
    return(
        <div className="band-member-element">
            <div className="band-member-image-container">
                <img src={image} alt="band-member" />
            </div>
            <div className="band-member-info">
                <h3 className="band-member-name">{name}</h3>
                <p className="band-member-instrument">{instrument}</p>
            </div>
        </div>
    );
}